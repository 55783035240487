import {initThemes} from 'initTheme';
import {getConfigData} from 'configCustom';
import {saveDataToLS} from 'utilsOld/configJSONUtils';
import {getThemeSettings, setSettings} from 'services/SecondaryMethods/userSettings';
import {system} from 'services/objects';
import './index.scss';

(async () => {
  const userConfig = await getConfigData();

  if (userConfig) {
    saveDataToLS(userConfig); //set default themes to local storage app_settings
  }

  const themeSettings = getThemeSettings(); //get saved themes from localStorage

  setSettings(system.USER_SETTINGS.THEME_SETTINGS, themeSettings);
  setSettings(system.USER_SETTINGS.EDITOR_STYLING_MODE, themeSettings.editorStylingMode);

  initThemes();// get themes from local storage app_settings

  if (themeSettings) {
    const {initApplication} = await import(/* webpackChunkName: "app" */ './App');
    initApplication(userConfig);
  }
})();
