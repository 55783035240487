import {system} from 'services/objects';
import {defaultBaseThemes} from 'components/forms/formSettings/AppearanceSettings/defaultThemes';
import {defaultSubSysThemes} from 'components/forms/formSettings/AppearanceSettings/defaultSubSysThemes';
import {defaultRightPanelThemes} from 'components/forms/formSettings/AppearanceSettings/defaultRightPanelThemes';
import {createThemeSettingsFromLS} from 'utilsOld/configJSONUtils';
import {isObject} from 'services/SecondaryMethods/typeUtils';

/**
 * !!!!!!!!!Внимание в этот модуль нельзя подключать node_modules
 */

if (!getApplicationLang()) {
  setSettings(system.USER_SETTINGS.APPLICATION_LANG, 'en');
}

export function _createSettingName (name) {
  return name.toLowerCase();
}

export function setSettings (name, value) {
  let appSets = JSON.parse(localStorage.getItem(system.USER_SETTINGS.APP_SETTINGS) || '{}');
  appSets[_createSettingName(name)] = value;
  localStorage.setItem(system.USER_SETTINGS.APP_SETTINGS, JSON.stringify(appSets));
}

export function getSettings () {
  return JSON.parse(localStorage.getItem(system.USER_SETTINGS.APP_SETTINGS) || '{}');
}

/**
 *
 * @param themeId {string}
 * @param themeDS {Array}
 * @returns {Object}
 */
export const themeById = (themeId, themeDS) => {
  return themeDS.find(theme => theme.id === themeId);
};

export function clearLocalAndSessionStorage () {
  let appSets = JSON.parse(localStorage.getItem(system.USER_SETTINGS.APP_SETTINGS) || '{}');
  delete appSets['themesettings'];
  localStorage.setItem(system.USER_SETTINGS.APP_SETTINGS, JSON.stringify(appSets));
  sessionStorage.clear();
}

export function getThemeSettings () {
  const appSettings = getSettings();

  const jsonThemes = createThemeSettingsFromLS();

  const defaultCommonTheme = themeById(system.DEFAULT_LOCALS.DEFAULT_BASE_THEME, defaultBaseThemes);
  const defSubSysTheme = themeById(system.DEFAULT_LOCALS.DEFAULT_SUB_SYS_THEME, defaultSubSysThemes);
  const defRightPanelTheme = themeById(system.DEFAULT_LOCALS.DEFAULT_RIGHT_PANEL_THEME, defaultRightPanelThemes);

  appSettings.themesettings = appSettings.themesettings ? appSettings.themesettings : {};

  const customThemes = appSettings.customthemes || [];

  Object.entries(appSettings.themesettings).forEach(([_, value]) => {
    const isTheme = isObject(value) && value.hasOwnProperty('src');

    if (isTheme) {
      const theme = themeById(value.id, customThemes);
      const isCustomTheme = !!theme;

      // если это кастомная тема, нужно обновить данные в ней
      // на тот случай, если обновили config.json
      if (isCustomTheme) {
        value.src = theme.src;
        value.text = theme.text;
        value.additional = theme.additional;
      }
    }
  });

  appSettings.themesettings.commonTheme = appSettings.themesettings.commonTheme || jsonThemes.themeSettings.commonTheme || defaultCommonTheme;
  appSettings.themesettings.subSysTheme = appSettings.themesettings.subSysTheme || jsonThemes.themeSettings.subSysTheme || defSubSysTheme;
  appSettings.themesettings.rightPanelTheme = appSettings.themesettings.rightPanelTheme|| jsonThemes.themeSettings.rightPanelTheme || defRightPanelTheme;

  appSettings.themesettings.editorStylingMode = appSettings.themesettings.editorStylingMode || jsonThemes.themeSettings.editorStylingMode;

  return appSettings.themesettings;
}

export function getRegionalSettings () {
  return getSettings()[_createSettingName(system.USER_SETTINGS.REGIONAL_SETTINGS)] || {};
}

export function getApplicationLang () {
  return getSettings()[_createSettingName(system.USER_SETTINGS.APPLICATION_LANG)];
}

export function getDrawerTheme () {
  return getSettings()[_createSettingName(system.USER_SETTINGS.DRAWER_THEME_NAME)];
}
