import {getThemeSettings} from 'services/SecondaryMethods/userSettings';
import {userScriptBaseUrl} from 'services/baseUrl';

/**
 * !!!!!!!!!Внимание в этот модуль нельзя подключать node_modules
 */

export function initThemes () {
  const themeSettings = getThemeSettings();
  const {commonTheme, subSysTheme, rightPanelTheme} = themeSettings;

  const stylesheetLink = document.createElement('link');

  stylesheetLink.setAttribute('rel', 'dx-theme');
  stylesheetLink.setAttribute('data-theme', commonTheme.id);
  stylesheetLink.setAttribute('href', commonTheme.src);
  stylesheetLink.setAttribute('data-active', 'true');

  document.querySelector('head').appendChild(stylesheetLink);

  const subSysThemeLink = document.createElement('link');
  subSysThemeLink.setAttribute('href', subSysTheme.src);
  subSysThemeLink.setAttribute('rel', 'stylesheet');
  document.querySelector('head').appendChild(subSysThemeLink);

  const rightPanelThemeLink = document.createElement('link');
  rightPanelThemeLink.setAttribute('href', rightPanelTheme.src);
  rightPanelThemeLink.setAttribute('rel', 'stylesheet');
  document.querySelector('head').appendChild(rightPanelThemeLink);

  const customStylesLink = document.createElement('link');
  customStylesLink.setAttribute('href', `${userScriptBaseUrl}/styles.css`);
  customStylesLink.setAttribute('rel', 'stylesheet');
  document.querySelector('head').appendChild(customStylesLink);
}
