import {getSettings, setSettings} from 'services/SecondaryMethods/userSettings';
import {defaultBaseThemes} from '../components/forms/formSettings/AppearanceSettings/defaultThemes';
import {defaultSubSysThemes} from '../components/forms/formSettings/AppearanceSettings/defaultSubSysThemes';
import {defaultRightPanelThemes} from '../components/forms/formSettings/AppearanceSettings/defaultRightPanelThemes';
import {system} from 'services/objects';
import {isObject} from 'services/SecondaryMethods/typeUtils';

/**
 * !!!!!!!!!Внимание в этот модуль нельзя подключать node_modules
 */

const {GENERIC, MATERIAL, GENERIC_COMPACT, MATERIAL_COMPACT} = system.THEME_TYPES;


export function getThemeTypeKey (type = '') {
  switch (type) {
    case GENERIC:
      return system.THEME_TYPES_NAMES.GENERIC_NAME;
    case GENERIC_COMPACT:
      return system.THEME_TYPES_NAMES.GENERIC_COMPACT_NAME;
    case MATERIAL:
      return system.THEME_TYPES_NAMES.MATERIAL_NAME;
    case MATERIAL_COMPACT:
      return system.THEME_TYPES_NAMES.MATERIAL_COMPACT_NAME;
    default:
      return null;
  }
}

const {
  DEFAULT_THEME, DEFAULT_EDITOR_MODE,
  CUSTOM_THEMES,
  SYSTEM_TOOLBAR_THEME, SUBSYSTEM_PANEL_THEME,
} = system.CONFIG_PARAMS;

/**
 * save data to Local Storage
 * @param defaultTheme {string}
 * @param defaultEditorMode {string}
 * @param subsystemsPanel {object}
 * @param systemToolbar {object}
 * @param customThemes {Array}
 */
export const saveDataToLS = ({
                               application:
                                 {
                                   defaultTheme, defaultEditorMode,
                                   subsystemsPanel, systemToolbar,
                                   customThemes,
                                 }
                             }) => {
  if (defaultTheme) {
    setSettings(DEFAULT_THEME, defaultTheme);
  }
  if (defaultEditorMode) {
    setSettings(DEFAULT_EDITOR_MODE, defaultEditorMode);
  }
  if (subsystemsPanel && subsystemsPanel.defaultTheme) {
    setSettings(SUBSYSTEM_PANEL_THEME, subsystemsPanel.defaultTheme);
  }
  if (systemToolbar && systemToolbar.defaultTheme) {
    setSettings(SYSTEM_TOOLBAR_THEME, systemToolbar.defaultTheme);
  }
  if (customThemes) {
    setSettings(CUSTOM_THEMES, customThemes);
  }
};

export function isGenericCompact (value = '') {
  return value.match(/^generic\.(.*)\.compact$/);
}

export function isGeneric (value = '') {
  return !isGenericCompact(value) && value.match(/^generic\.(.*)$/);
}

export function isMaterialCompact (value = '') {
  return value.match(/^material\.(.*)\.compact$/);
}

export function isMaterial (value = '') {
  return !isMaterialCompact(value) && value.match(/^material\.(.*)$/);
}

export function getThemeType (value = '') {

  if (isGenericCompact(value)) {
    return GENERIC_COMPACT;
  }

  if (isGeneric(value)) {
    return GENERIC;
  }

  if (isMaterialCompact(value)) {
    return MATERIAL_COMPACT;
  }

  if (isMaterial(value)) {
    return MATERIAL;
  }

  throw new Error('Unknown theme type');
}

export function getSwatchClass (value = '') {

  let match = isGenericCompact(value);

  if (match) {
    return 'dx-swatch-' + match[1];
  }

  match = isGeneric(value);

  if (match) {
    return 'dx-swatch-' + match[1];
  }

  match = isMaterialCompact(value);

  if (match) {
    return 'dx-swatch-' + match[1];
  }

  match = isMaterial(value);

  if (match) {
    return 'dx-swatch-' + match[1];
  }

  throw new Error('Unknown theme type');
}

/**
 * return the object with selected theme from theme data source
 * @param themeId {string}
 * @param themeDataSource {object}
 * @returns {object}
 */
const defaultCommonThemeFromJSON = (themeId, themeDataSource) => {
  if (themeId) {
    const type = getThemeType(themeId);
    const typeKey = getThemeTypeKey(type);
    const themeDSObject = themeDataSource.find(object => object.key === typeKey);
    const checkThemeFromJSON = themeDSObject
      && themeDSObject['items'] && themeDSObject['items'].length
      && !!themeDSObject['items'].find(theme => theme.id === themeId);
    if (checkThemeFromJSON) {
      return themeDSObject['items'].find(theme => theme.id === themeId);
    }
  }
};

/**
 * @param themesList {array}
 * @param isAdditional {boolean}
 * @returns {[{items: [], key: string}, {items: [], key: string}, {items: [], key: string}, {items: [], key: string}]}
 */
export const createThemesDataSource = (themesList, isAdditional) => {
  const {
    THEME_TYPES_NAMES: {
      GENERIC_NAME, GENERIC_COMPACT_NAME, MATERIAL_NAME, MATERIAL_COMPACT_NAME
    }
  } = system;
  const dataSource = [{
    key: GENERIC_NAME,
    items: [],
  }, {
    key: GENERIC_COMPACT_NAME,
    items: [],
  }, {
    key: MATERIAL_NAME,
    items: [],
  }, {
    key: MATERIAL_COMPACT_NAME,
    items: [],
  }];

  themesList.forEach(theme => {
    /**
     * @type id {string}
     * @type text {string}
     * @type additional {boolean}
     */
    const {id, text, additional} = theme;
    const type = getThemeType(id);
    /**
     * check for correct data
     */
    if (!text || (typeof additional !== 'boolean')) return;
    /**
     * divide to additional and main themes according to incoming {isAdditional}
     * and {theme.additional} parameter
     */
    if (!isAdditional && additional) return;
    if (!dataSource[type]) return;

    dataSource[type].items.push({
      ...theme,
      type,
    });
  });

  return dataSource;
};

/**
 * sets the default theme from config json
 */
export function createThemeSettingsFromLS () {
  const appSettings = getSettings();
  const customThemes = appSettings.customthemes && appSettings.customthemes.length ? appSettings.customthemes : [];

  const themesDataSource = createThemesDataSource([...defaultBaseThemes, ...customThemes], false);
  const subSysDataSource = createThemesDataSource([...defaultSubSysThemes, ...customThemes], true);
  const rightPanelDataSource = createThemesDataSource([...defaultRightPanelThemes, ...customThemes], true);

  let commonTheme = defaultCommonThemeFromJSON(appSettings.defaulttheme, themesDataSource);
  let subSysTheme = defaultCommonThemeFromJSON(appSettings.subsystemspaneltheme, subSysDataSource);
  let rightPanelTheme = defaultCommonThemeFromJSON(appSettings.systemtoolbartheme, rightPanelDataSource);
  let editorStylingMode = appSettings.defaulteditormode;

  return {
    themeSettings: {
      commonTheme, subSysTheme,
      rightPanelTheme, editorStylingMode
    },
  };
}

/**
 * compare two theme objects
 * @param themeSettingsSelector {object}
 * @param defaultThemeObject {object}
 * @returns {boolean}
 */
export const isEqualValues = (themeSettingsSelector, defaultThemeObject) => {
  let result = [];
  const themeDefKeys = Object.keys(defaultThemeObject);
  if (!isObject(defaultThemeObject) && Object.keys(defaultThemeObject).length) {
    result = themeDefKeys.map(key => {
      if (Boolean(defaultThemeObject[key])) {
        if (key === 'editorStylingMode') {
          return defaultThemeObject[key] === themeSettingsSelector[key];
        } else {
          return defaultThemeObject[key].id === themeSettingsSelector[key].id;
        }
      }
      return true;
    });
    return !(result.length && result.some(el => el === false));
  }
};
