const types: {[key: string]: string} = {
  '[object Array]': 'array',
  '[object Date]': 'date',
  '[object Object]': 'object',
  '[object String]': 'string',
  '[object Null]': 'null'
};

const type = function (object: Record<string, any>): string {
  const typeOfObject = Object.prototype.toString.call(object);

  return typeof object === 'object' ?
    types[typeOfObject] || 'object' : typeof object;
};

const isBoolean = function (object: any): boolean {
  return typeof object === 'boolean';
};

const isDate = function (object: any): boolean {
  return type(object) === 'date';
};

/**
 * Returns true if the passed value isn't null or undefined.
 * @param value
 */
const isDefined = function (value: any): boolean {
  return (value !== null) && (value !== undefined);
};

const isFunction = function (object: any): boolean {
  return typeof object === 'function';
};

const isString = function (object: any): boolean {
  return typeof object === 'string';
};

const isNumeric = function (object: any): boolean {
  return ((typeof object === 'number') && isFinite(object)) || !isNaN(object - parseFloat(object));
};

const isObject = function (value: any): boolean {
  return type(value) === 'object' && !isNull(value) && !isArray(value) && !isDate((value));
};

const isEmptyObject = function (object: Record<string, any>): boolean {
  for (let property in object) {
    return false;
  }

  return true;
};

const isPromise = function (object: Record<string, any>): boolean {
  return object && isFunction(object.then);
};

const isNull = function (value: any): boolean {
  return type(value) === 'null';
};

const isArray = function (value: any): boolean {
  return type(value) === 'array';
};


export {
  isFunction,
  isArray,
  isString,
  isNumeric,
  isBoolean,
  isDate,
  isDefined,
  isPromise,
  isEmptyObject,
  type,
  isObject,
  isNull
};
