import {userResourcesBaseUrl} from 'services/baseUrl';
import {system} from 'services/objects';

/**
 * !!!!!!!!!Внимание в этот модуль нельзя подключать node_modules
 */


const url = `${userResourcesBaseUrl}/${system.FILE_NAMES.CONFIG}.json?${Math.random()}`;
/**
 * need for getting user settings from server
 * @type {function}
 * @returns {Promise<config.json | null>}
 * */

export const getConfigData = () => fetch(url)
  .then(async response => {
    if (response.ok) {
      return await response.json();
    }
    return null;
  })
  .catch(() => null);
