export const defaultSubSysThemes = [
  {
    'id': 'generic.light.compact',
    'src': 'libs/dx/css/subsystem/generic.light.compact.css',
    'text': 'Generic Compact: Light',
    'type': 1,
    'additional': true,
  },
  {
    'id': 'generic.light',
    'src': 'libs/dx/css/subsystem/generic.light.css',
    'text': 'Generic: Light',
    'type': 0,
    'additional': true,
  },
  {
    'id': 'generic.dark',
    'src': 'libs/dx/css/subsystem/generic.dark.css',
    'text': 'Generic: Dark',
    'type': 0,
    'additional': true,
  },
  {
    'id': 'generic.carmine',
    'src': 'libs/dx/css/subsystem/generic.carmine.css',
    'text': 'Generic: Carmine',
    'type': 0,
    'additional': true,
  },
  {
    'id': 'generic.darkmoon',
    'src': 'libs/dx/css/subsystem/generic.darkmoon.css',
    'text': 'Generic: Dark Moon',
    'type': 0,
    'additional': true,
  },
  {
    'id': 'generic.softblue',
    'src': 'libs/dx/css/subsystem/generic.softblue.css',
    'text': 'Generic: Soft Blue',
    'type': 0,
    'additional': true,
  },
  {
    'id': 'generic.darkviolet',
    'src': 'libs/dx/css/subsystem/generic.darkviolet.css',
    'text': 'Generic: Dark Violet',
    'type': 0,
    'additional': true,
  },
  {
    'id': 'generic.greenmist',
    'src': 'libs/dx/css/subsystem/generic.greenmist.css',
    'text': 'Generic: Green Mist',
    'type': 0,
    'additional': true,
  },
  {
    'id': 'generic.dark.compact',
    'src': 'libs/dx/css/subsystem/generic.dark.compact.css',
    'text': 'Generic Compact: Dark',
    'type': 1,
    'additional': true,
  },
  {
    'id': 'generic.carmine.compact',
    'src': 'libs/dx/css/subsystem/generic.carmine.compact.css',
    'text': 'Generic Compact: Carmine',
    'type': 1,
    'additional': true,

  },
  {
    'id': 'generic.darkmoon.compact',
    'src': 'libs/dx/css/subsystem/generic.darkmoon.compact.css',
    'text': 'Generic Compact: Dark Moon',
    'additional': true,

  },
  {
    'id': 'generic.softblue.compact',
    'src': 'libs/dx/css/subsystem/generic.softblue.compact.css',
    'text': 'Generic Compact: Soft Blue',
    'type': 1,
    'additional': true,

  },
  {
    'id': 'generic.darkviolet.compact',
    'src': 'libs/dx/css/subsystem/generic.darkviolet.compact.css',
    'text': 'Generic Compact: Dark Violet',
    'type': 1,
  },
  {
    'id': 'generic.greenmist.compact',
    'src': 'libs/dx/css/subsystem/generic.greenmist.compact.css',
    'text': 'Generic Compact: Green Mist',
    'type': 1,
    'additional': true,

  },
  {
    'id': 'material.blue.light',
    'src': 'libs/dx/css/subsystem/material.blue.light.css',
    'text': 'Material: Blue Light',
    'type': 2,
    'additional': true,

  },
  {
    'id': 'material.orange.light',
    'src': 'libs/dx/css/subsystem/material.orange.light.css',
    'text': 'Material: Orange Light',
    'type': 2,
    'additional': true,

  },
  {
    'id': 'material.lime.light',
    'src': 'libs/dx/css/subsystem/material.lime.light.css',
    'text': 'Material: Lime Light',
    'type': 2,
    'additional': true,

  },
  {
    'id': 'material.purple.light',
    'src': 'libs/dx/css/subsystem/material.purple.light.css',
    'text': 'Material: Purple Light',
    'type': 2,
    'additional': true,

  },
  {
    'id': 'material.teal.light',
    'src': 'libs/dx/css/subsystem/material.teal.light.css',
    'text': 'Material: Teal Light',
    'type': 2,
    'additional': true,

  },
  {
    'id': 'material.blue.dark',
    'src': 'libs/dx/css/subsystem/material.blue.dark.css',
    'text': 'Material: Blue Dark',
    'type': 2,
    'additional': true,

  },
  {
    'id': 'material.orange.dark',
    'src': 'libs/dx/css/subsystem/material.orange.dark.css',
    'text': 'Material: Orange Dark',
    'type': 2,
    'additional': true,

  },
  {
    'id': 'material.lime.dark',
    'src': 'libs/dx/css/subsystem/material.lime.dark.css',
    'text': 'Material: Lime Dark',
    'type': 2,
    'additional': true,

  },
  {
    'id': 'material.purple.dark',
    'src': 'libs/dx/css/subsystem/material.purple.dark.css',
    'text': 'Material: Purple Dark',
    'type': 2,
    'additional': true,

  },
  {
    'id': 'material.teal.dark',
    'src': 'libs/dx/css/subsystem/material.teal.dark.css',
    'text': 'Material: Teal Dark',
    'type': 2,
    'additional': true,

  },
  {
    'id': 'material.blue.light.compact',
    'src': 'libs/dx/css/subsystem/material.blue.light.compact.css',
    'text': 'Material Compact: Blue Light',
    'type': 3,
    'additional': true,

  },
  {
    'id': 'material.orange.light.compact',
    'src': 'libs/dx/css/subsystem/material.orange.light.compact.css',
    'text': 'Material Compact: Orange Light',
    'type': 3,
    'additional': true,

  },
  {
    'id': 'material.lime.light.compact',
    'src': 'libs/dx/css/subsystem/material.lime.light.compact.css',
    'text': 'Material Compact: Lime Light',
    'type': 3,
    'additional': true,

  },
  {
    'id': 'material.purple.light.compact',
    'src': 'libs/dx/css/subsystem/material.purple.light.compact.css',
    'text': 'Material Compact: Purple Light',
    'type': 3,
    'additional': true,

  },
  {
    'id': 'material.teal.light.compact',
    'src': 'libs/dx/css/subsystem/material.teal.light.compact.css',
    'text': 'Material Compact: Teal Light',
    'type': 3,
    'additional': true,

  },
  {
    'id': 'material.blue.dark.compact',
    'src': 'libs/dx/css/subsystem/material.blue.dark.compact.css',
    'text': 'Material Compact: Blue Dark',
    'type': 3,
    'additional': true,

  },
  {
    'id': 'material.orange.dark.compact',
    'src': 'libs/dx/css/subsystem/material.orange.dark.compact.css',
    'text': 'Material Compact: Orange Dark',
    'type': 3,
    'additional': true,

  },
  {
    'id': 'material.lime.dark.compact',
    'src': 'libs/dx/css/subsystem/material.lime.dark.compact.css',
    'text': 'Material Compact: Lime Dark',
    'type': 3,
    'additional': true,

  },
  {
    'id': 'material.purple.dark.compact',
    'src': 'libs/dx/css/subsystem/material.purple.dark.compact.css',
    'text': 'Material Compact: Purple Dark',
    'type': 3,
    'additional': true,

  },
  {
    'id': 'material.teal.dark.compact',
    'src': 'libs/dx/css/subsystem/material.teal.dark.compact.css',
    'text': 'Material Compact: Teal Dark',
    'type': 3,
    'additional': true,

  },
];
